.custom-navbar {
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    flex-direction: row;
    -webkit-border-radius: 20px 20px 0px 0px;
    -moz-border-radius: 20px 20px 0px 0px;
    border-radius: 20px 20px 00px 0px;
    border: 0px;
    background-color: #FFFFFF;
}

.horizontal-orientation {
    display: inline;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.avatar-icon{
    width: 45px;
    height: 45px;
}

.bottom-icons {
    width: 25px;
    height: 25px;
    margin-left: 5vw;
    margin-bottom: 5px;
}
