.div-col-rating-items {
    display: table;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
}

.ratings-head-text {
    padding: 10px;
    user-select: none;
    font-weight: bold;
    color: white;
}

.ratings-head-title {
    width: 92%;
    height: 60px;
    margin: auto;
    background-image: url(../assets/images/title-background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-size: 100% 100%;
}

.div-row-rating-items {
    margin-top: 3%;
    height: 40%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 460px) {
    .div-row-rating-items {
        margin-top: 0%;
        width: 99%;
        height: auto;
        position: revert;
        flex-direction: column;
        justify-content: left;
        align-items: center;
    }

    .rating-item {
        min-width: 18vw;
    }

    .rating-item-middle {
        min-width: 21vw;
        margin-left: 0%;
        min-height: 12vh;
    }

    .rating1-item {
        text-align: center;
        color: white;
        position: fixed;
        min-width: 10rem;
        min-height: 7rem;
    }
}

.rating-item {
    width: 21vw;
}

.rating-item-middle {
    width: 21vw;
    margin-left: 18%;
}

.rating1-item {
    position: relative;
    text-align: center;
    color: white;
    width: 12vw;
}

.img-div {
    padding: 8px 0px;
    min-width: 21vw;
    min-height: 12vh;
    position: relative;
    text-align: center;
    color: white;
}

.img-div-middle {
    padding: 8px 0px;
    width: 21vw;
    height: 12vh;
    position: relative;
    text-align: center;
    color: white;
}

.ask-text-in-rating {
    padding: 0px 0px;
    margin-top: 3%;
    margin-left: 4.2%;
    position: relative;
    text-align: left;
    color: white;
}

.answer-text-in-rating {
    padding: 0px 0px;
    left: 2%;
    top: 1%;
    width: 87%;
    font-size: x-small;
    font-weight: normal;
    position: relative;
    text-align: left;
    color: white;
}

.img-div:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.2);
    transform: scale(1.1, 1.2);
}

.img-text1 {
    position: absolute;
    top: 40%;
    left: 50%;
    font-weight: bold;
    font-size: 1.3vw;
    transform: translate(-50%, -50%);
}

.img-school-text1 {
    position: absolute;
    top: 52%;
    left: 50%;
    font-size: 0.9vw;
    transform: translate(-50%, -50%);
}

.img-rating-text1 {
    position: absolute;
    top: 60%;
    left: 50%;
    font-size: 1.3vw;
    font-style: italic;
    color: #1B1464;
    transform: translate(-50%, -50%);
}

.first-place-container {
    position: relative;
    text-align: center;
    color: white;

}

.img-school-text-items {
    position: absolute;
    top: 55%;
    left: 51%;
    font-size: 0.8vw;
    font-weight: bolder;
    transform: translate(-50%, -50%);
}

.img-text-items {
    position: absolute;
    top: 33%;
    left: 55%;
    font-weight: bolder;
    font-size: 1.2vw;
    transform: translate(-50%, -50%);
}

.img-rating-text-items {
    position: absolute;
    top: 73%;
    left: 50%;
    font-weight: bolder;
    font-style: italic;
    font-size: 1.3vw;
    color: #1B1464;
    transform: translate(-50%, -50%);
}

.middle-img-text-items {
    position: absolute;
    top: 36%;
    left: 73%;
    font-weight: bold;
    font-size: 1.2vw;
    transform: translate(-50%, -50%);
}

.middle-img-school-text-items {
    position: absolute;
    top: 60%;
    left: 65%;
    display: inline-block;
    white-space: nowrap;
    font-size: 0.8vw;
    transform: translate(-40%, -60%);
}

.img-middle-rating-text-items {
    position: absolute;
    top: 73%;
    left: 67%;
    font-weight: bolder;
    font-style: italic;
    font-size: 1.3vw;
    color: #1B1464;
    transform: translate(-50%, -50%);
}

.horizontal-line {
    width: 95%;
}

.ask-text {
    position: absolute;
    top: 25%;
    left: 43%;
    width: 40%;
    height: auto;
    font-weight: bolder;
    font-style: normal;
    font-size: 10px;
    transform: translate(-100%, -100%);
}

.rating-item-to-text {
    color: white;
    user-select: none;
}

.rating-item-to-text:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.2);
    transform: scale(1.1, 1.2);
}


.first-place-card-container {
    border-radius: 100%;
    width: 200px;
    height: 200px;
    background: linear-gradient(135deg, rgba(0,189,241,1) 0%, rgba(0,84,255,1) 65%, rgba(0,54,255,1) 100%);
    padding: 8px;
    color: white;
    line-height: 1;
}


.place-number-col {
    display: flex;
    justify-content: center;
    padding: 0;
}

.place-number {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #fff200;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    color: black;
    font-size: 32px;
    font-weight: bold;
    -webkit-box-shadow: 3px 3px 12px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 3px 12px -3px rgba(0,0,0,0.75);
    box-shadow: 3px 3px 12px -3px rgba(0,0,0,0.75);
}

.winner-info {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
    text-wrap: revert;
}

.winner-info .school {
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
}


.winner-info .points {
    margin-top: 5px;
    color: #1e347b;
    font-style: italic;
}

.places-container {
    width: 300px;
    height: 80px;
    border-radius: 40px;
    background: linear-gradient(135deg, rgba(0,189,241,1) 0%, rgba(0,84,255,1) 65%, rgba(0,54,255,1) 100%);
    padding: 6px;
    color: white;
    font-weight: bold;
    display: flex;
    margin: 0 0 10px 0;
}


.places-container .place-number {
    background-color: whitesmoke;
}

.places-container .winner-info {
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}
.places-container .winner-info span {
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.places-container .winner-info .school {
    margin-top: -3px;
    font-weight: normal;
    font-size: 12px;
}


.places-container .winner-info .points {
    margin-top: 0;
    font-size: 16px;
}

@media (max-width: 414px) {
    .first-place-card-container {
        width: 140px;
        height: 140px;
    }

    .first-place-card-container .place-number {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .winner-info span {
        font-size: 14px;
    }

    .winner-info .school {
        font-size: 10px;
    }


    .winner-info .points {
        font-size: 12px;
    }


    .places-container {
        width: 200px;
        height: 55px;
        padding: 5px;
    }

    .places-container .winner-info span {
        font-size: 14px;
    }

    .places-container .winner-info .school {
        font-size: 10px;
    }

    .places-container .winner-info .points {
        font-size: 12px;
    }
    .places-container .winner-info .full-name {
        margin-top: -6px;
    }

    .places-container .place-number {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
}

.ratings-section .more-link {
    text-decoration: none;
    color: white;
    font-size: 24px;
}

.ratings-section .more-link:hover {
    text-decoration: underline;
}