.div-row-rates-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width: 395px) {
    .div-row-rates-items {
        justify-content: center;
    }
}


.package-type-card {
    color: black;
    padding: 32px 0;
    letter-spacing: 1.3px;
    text-align: left;
    border-radius: 30px;
}

.package-type-card:hover {
    transform: translate(10, 10);
}

.package-type-card div * {
    display: block;
    color: #2f3640;
}

.package-type-card .name {
    font-weight: bold;
    font-size: 38px;
    line-height: 1;
}

.package-type-card .price {
    font-weight: bolder;
    font-size: 28px;
    margin-bottom: -3px;
    color: #487eb0;
}

.package-type-card .package-type-card-body div {
    font-size: 18px;
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 3px;
}

.package-type-card .accounts_quantity {
    margin-top: -5px;
}

.package-type-card .description-icon {
    font-size: 18px;
    margin-right: 8px;
}

.package-type-card .accounts_quantity {
    /*font-size: 18px;*/
    line-height: 1;
    margin-top: 2px;
}

.package-type-card .item_month_duration {
    /*font-size: 18px;*/
    line-height: 1;
}