.news-page-container {
    justify-content: center;
    background-image: url(../../assets/images/memory-mee-backgraund.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: rgb(5, 17, 142);
    position: relative;
    overflow: auto;
    height: 100vh;
}


.news-page-container .article-card-body {
    word-wrap: break-word;
    color: #2d3436;
    padding: 2rem;
}


.news-page-container .article-card-body h2 {
    text-align: left;
    font-weight: bold;
    padding-bottom: 8px;
}


.news-page-container .article-short-description {
    color: grey;
    text-align: left;
    margin-bottom: 0;
}


.news-page-container .article-created-at {
    color: #353b48;
    text-align: left;
    font-size: 1.2rem;
    margin-bottom: 20px;
}


.news-page-container .article-image {
    width: 100%;
    height: auto;
}


.news-page-container .article-payload {
    text-align: left;
    font-size: 1.1rem;
}


.news-page-container .recent-articles * {
    margin-bottom: 8px;
}

.news-page-container .see-all-link {
    width: 100%;
}