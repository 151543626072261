.developers-section {
    margin-top: 80px;
    margin-left: 6%;
}
.profile-container {
    width: 360px;
    height: 180px;
    border-radius: 100px 10px 10px 100px;
    background-color: rgba(21, 89, 206, 0.52);
    color: white;
    line-height: 1;
}

.avatar-col {
    margin: 0;
    padding: 0;
}
.avatar {
    width: 180px;
    height: 180px;
    border-radius: 100%;
}


@media (max-width: 414px) {
    .profile-container {
        width: 100%;
        height: 150px;
    }

    .avatar {
        width: 150px;
        height: 150px;
    }
}