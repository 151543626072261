.sighnup-root {
    justify-content: center;
    background-image: url('../assets/images/memory-mee-backgraund.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: rgb(5, 17, 142);
    position: relative;
    overflow: scroll;
    height: 100vh;
}

.signup-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.64);
    width: 60%;
    margin-left: 20%;
    margin-top: 2%;
    margin-bottom: 2%;
}

input[type=text] {
    width: 100%;
    height: 2vh;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
    background: #f1f1f1;
}

input[type=text]:focus {
    background-color: #ddd;
    outline: none;
}

hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
}

button {
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
}

button:hover {
    opacity: 1;
}

.cancelbtn {
    padding: 14px 20px;
    background-color: #f44336;
}

.cancelbtn, .signupbtn {
    float: left;
    width: 50%;
}

.sighnup-container {
    padding: 50px;
}


@media screen and (max-width: 300px) {
    .cancelbtn, .signupbtn {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    input[type=text] {
        width: 100%;
        height: 1.5vh;
        padding: 12px;
        margin: 5px 0 22px 0;
        display: inline-block;
        border: none;
        background: #f1f1f1;
    }

    .clearfix::after {
        content: "";
        clear: both;
        display: table;
    }


    .signup-card {
        background-color: rgba(255, 255, 255, 0.64);
        width: 90%;
        margin-left: 5%;
        margin-top: 2%;
    }
}

.bluebtn-img-div {
    padding: 0px 0px 0px 0px;
    min-width: 21vw;
    width: 50%;
    border-radius: 50%;
    min-height: auto;
    position: relative;
    text-align: center;
    color: white;
}
.bluebtn-img-div:hover {
    box-shadow: 5px 5px 30px 15px rgba(49, 255, 243, 0.79),
    -5px -5px 30px 15px rgba(14, 38, 255, 0.49);
}

.bluebtn-img-div:active {
  background-color: #5c6ac4;
  box-shadow: 0 0.125em #666666;
  transform: translateY(0.25em);
}


.yellowbtn-img-div {
    padding: 0px 0px 0px 0px;
    min-width: 21vw;
    width: 45%;
    margin-left: 5px;
    border-radius: 50%;
    min-height: auto;
    position: relative;
    text-align: center;
    color: white;
}
.yellowbtn-img-div:hover {
    box-shadow: 5px 5px 30px 15px rgba(230, 255, 109, 0.69),
    -5px -5px 30px 15px rgba(119, 255, 67, 0.49);
}

.yellowbtn-img-div:active {
  background-color: #5c6ac4;
  box-shadow: 0 0.125em #666666;
  transform: translateY(0.25em);
}

.signupbtn-item {
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
    height: 11vh;
}

.signupbtn-text {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 25vw;
    font-weight: 700;
    font-size: 3vh;
    transform: translate(-50%, -50%);
}

.school-description{
    width: 100%;
    height: 20vh;
    padding: 15px;
    text-align: left;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
}