
.blog-section .section-title-container a {
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
}

.blog-section .section-title-container a:hover {
    text-decoration: underline;
    color: whitesmoke;
}

