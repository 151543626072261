.rating-archive-component {
    justify-content: center;
    background-image: url(../../assets/images/memory-mee-backgraund.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: rgb(5, 17, 142);
    position: relative;
    overflow: auto;
    height: 100vh;
}

.rating-archive-component .place-td {
    background-color: #FAFF00;
}

.rating-archive-item {
    position: relative;
    text-align: center;
    color: white;
    height: 3em;
}

.rating-archive-img-form {
    display: flex;
    flex-direction: row;
    user-select: none;
    margin: 0 auto;
}

@media (orientation: portrait) {
    .rating-archive-img-form {
        display: flex;
        flex-direction: row;
        width: 90%;
        margin-top: 1vh;
    }

    .rating-archive-item {
        position: relative;
        text-align: center;
        color: white;
        width: 35%;
        height: 8vh;
    }

}