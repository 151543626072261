
.info-head-text {
    padding: 10px;
    user-select: none;
    font-weight: bold;
}

.info-head-title {
    width: 90%;
    height: 60px;
    margin: auto;
    background-image: url(../../assets/images/title-background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-size: 100% 100%;
}

.info-img-text-items {
    position: absolute;
    top: 33%;
    left: 55%;
    font-weight: bolder;
    font-size: 1.2vw;
    transform: translate(-50%, -50%);
}

.div-row-info-items {
    margin-top: 3%;
    width: 95%;
    height: 40%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-left: 2vw;
}

.info-video-div {
    width: 53vw;
    height: 30vw;
    border-radius: 30px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.info-text-div {
    width: 30vw;
    text-align: left;
    margin: 5px;
}

.info-video-play-button {
    border: 20px;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 74px;
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
    cursor: pointer;
    border-style: dashed;
    border-width: 37px 0 37px 60px;
}

.info-video-play-button.paused {
    border-style: double;
    border-width: 0px 0 0px 60px;
}

.info-video-play-button:hover {
    border-color: transparent transparent transparent #404040;
}

@media screen and (max-width: 800px) {
    .info-video-div {
        width: 80%;
        height: 40vh;
        border-radius: 30px;
    }

    .info-text-div {
        margin-top: 10px;
        min-width: 80%;
        height: 25vw;
    }

    .info-description_text {
        font-size: 10px;
        text-align: left;
    }
}

@media screen and (max-width: 460px) {
    .info-description_text {
        font-size: 10px;
        text-align: left;
    }
}

@media screen and (min-width: 460px) {
    .info-description_text {
        font-family: "Montserrat Alternates", "Open Sans", Helvetica, Arial, sans-serif;
        font-size: 1.5vw;
        text-indent: 2em;
        text-align-all: left;
        letter-spacing: 1px;
        word-spacing: 1px;
        color: white;
        font-weight: 400;
        text-transform: none;
    }
}

