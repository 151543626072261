html, body {
    justify-content: center;
    background-image: url(../assets/images/memory-mee-backgraund.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.article-card-container {
    background-color: white;
    border-radius: 10px;
    text-align: left;
    word-wrap: break-word;
    line-height: 1.2;
}

.article-card-container .article-image {
    width: 100%;
    border-radius: 10px 10px 0 0;
    margin: 0;
}

.article-card-container .article-body {
    padding: 10px;
}

.article-card-container .article-body .title-container {
    height: 100px;
    overflow: hidden;
}

.article-card-container .article-body .action-container {
    background: white;
}

.article-card-container a {
    margin: 0;
    font-size: 20px;
    text-decoration: none;
    color: #2d3436;
}

.article-card-container button {
    font-size: 14px;
}

.article-card-container a:hover {
    color: #2d3436;
    text-decoration: underline;
}

.article-card-container .article-body .article-short-description {
    margin: 3px 0;
    word-wrap: break-word;
    color: #353b48;
}

.transparent-navbar .nav-link {
    color: white;
    font-size: 1.1rem;
}


.section-title-container {
    color: white;
    margin: 20px 5% 20px 5%;
    padding: 0 25px;
    display: flex;
    justify-self: center;
    justify-content: left;
    justify-items: left;
    align-items: center;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,170,255,1) 65%, rgba(0,170,255,1) 100%);
    border-radius: 10px;
}
.section-title-container .section-title {
    padding: 0 20px;
    text-align: left;
}

.section-title-container .section-title p {
    margin: 0;
    padding: 15px 0;
    font-weight: 900;
    font-size: 32px;
}

.section-title-container .icon {
    font-size: 32px;
}
.section-title-container .title-icon {
    font-size: 32px;
    margin-right: 8px;
}
.section-title-container .second-child {
    flex-grow: 1;
}

@media (max-width: 700px) {
    .section-title-container {
        margin-top: 30px;
    }
    .section-title-container .section-title {
        padding: 0 0;
    }
    .section-title-container .section-title p {
        font-size: 20px;
        line-height: 1;
    }
    .section-title-container .icon {
        font-size: 20px;
    }
    .section-title-container .title-icon {
        font-size: 20px;
    }

    .section-title-container a {
        font-size: 10px;
    }
}
