.main-page-root {
    background-image: url('../assets/images/memory-mee-backgraund.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: rgb(5, 17, 142);
    position: relative;
    overflow: hidden;
}

.home-component {
    background-image: url("../assets/images/memory-mee-backgraund.jpg");
    width: 100%;
    height: 100%;
}

.side-bar {
    -webkit-border-radius: 0px 40px 40px 0px;
    -moz-border-radius: 0px 40px 40px 0px;
    border-radius: 0px 40px 40px 0px;
    background-color: #FFFFFF;
    height: 100%;
}

.side-bar-div {
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #000062;
    position: relative;
    height: 100%;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 10px;
    overflow: hidden;
    background-color: rgba(51, 51, 51, 0);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}

li {
    float: left;
}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

li a:hover {
    background-color: #111;
}

.active {
    background-color: #4CAF50;
}

.app-bar-div {
    display: flex;
    margin-left: 25vw;
    flex-direction: row;
    flex-wrap: nowrap;
}

div.ext-box {
    display: table;
    width: 100%;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
}

div.int-box {
    display: table-cell;
    vertical-align: middle;
}


/*react-full-screen scroller styles*/
html, body {
    margin: 0;
    height: 100%;
    width: 100%;
}

.title {
    margin: 20px auto 0;
    text-align: center;
}

.links {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.links .link {
    margin-top: 20px;
}

.component {
    height: 100%;
    width: 100%;
    display: flex;
}

.home-container {
    position: relative;
    text-align: center;
    color: white;
}

.component.first-component {
    background-image: url("../assets/images/background-main-page.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #000062;
    position: relative;
    height: 100vh;
    margin-bottom: 10vh;
    animation: scroll 5s infinite cubic-bezier(.42, -0.20, .1, 1);
}


.component.first-component:hover {
    animation-play-state: paused;
}


.component.second-component {
    background-image: url("../assets/images/memory-mee-backgraund.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    position: relative;
    height: 100vh;
}

.column-style {
    padding-top: 5rem;
    padding-left: 5rem;
    width: 99%;
    vertical-align: middle;
}

.component.third-component {

    background-color: rgba(0, 0, 0, 0);
}

.component.fourth-component {

    background-color: rgba(98, 9, 48, 0);
}

.component.fifth-component {

    background-color: rgba(255, 251, 71, 0);
}

h2 {
    margin: auto;
    text-align: center;
}

.pagination-additional-class {
    margin-left: 15vw;
    position: fixed;
    top: 13px;
    display: flex;
    justify-content: left;
    width: 100%;
}

.pagination-additional-class > li:not(:last-of-type) > a, .pagination-additional-class > li:not(:last-of-type) > span {
    margin-right: 50px;
}

.demo-page-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.demo-page-contain__hint {
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    html, body {
        height: auto;
    }

    .component .second-component {
        height: auto;
    }

    .pagination-additional-class > li:not(:last-of-type) > a, .pagination-additional-class > li:not(:last-of-type) > span {
        margin-right: 5px;
    }

    .component.first-component {
        background-image: url("../assets/images/memory-mee-backgraund.jpg");
        background-position: center top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-color: #000062;
        position: relative;
        height: 100vh;
        padding-top: 6rem;
        padding-bottom: 5rem;
        justify-content: center;
        animation: scroll 5s infinite cubic-bezier(.42, -0.20, .1, 1);
    }

    .component.second-component {
        height: auto;
    }

}

.drawer {
    width: 30px;
    flex-shrink: 0;
    background-color: rgba(251, 251, 255, 0);
    border: 0px;
}

.drawer-paper {
    width: 30px;
    border: 0px;
}

.content {
    flex-grow: 1;
    background-color: #000062;
    border: 0px;
}

.nav-buttons {
    width: 10vw;
    background-color: #1455A2;
    border-radius: 50px;
    margin: 10px;
    text-transform: none;
    color: #ffffff;
}

/*Side-navigation*/
.side-icon-facebook {
    background-image: url("./../assets/images/facebook-icon.png");
    border: none;
    width: 25px;
    height: 25px;
    border-radius: 360px;
    justify-content: center;
    background-size: cover;
    position: relative;
}

.side-icon-youtube {
    background-image: url("./../assets/images/youtube-icon.png");
    border: none;
    width: 25px;
    height: 25px;
    border-radius: 360px;
    justify-content: center;
    background-size: cover;
    position: relative;
}

.side-icon-insta {
    background-image: url("./../assets/images/insta-icon.png");
    border: none;
    width: 25px;
    height: 25px;
    border-radius: 360px;
    justify-content: center;
    background-size: cover;
    position: relative;
}

.side-icon-whatsapp {
    background-image: url("./../assets/images/whats-app-icon.png");
    border: none;
    width: 25px;
    height: 25px;
    border-radius: 360px;
    justify-content: center;
    background-size: cover;
    position: relative;
}

.side-icon:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.2);
    transform: scale(1.1, 1.2);
}

.share-icon {
    background-image: url("./../assets/images/share-icon.png");
    border: none;
    top: 80%;
    width: 25px;
    height: 25px;
    border-radius: 360px;
    justify-content: center;
    color: white;
    padding: 5px 8px;
    font-size: 16px;
}

.share-icon:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.2);
    transform: scale(1.1, 1.2);
}

.side-avatar-icon {
    background-image: url('../assets/images/logo_memoryMee.png');
    background-size: cover;
    border: none;
    margin-top: 10px;
    margin-left: 18%;
    width: 60px;
    height: 60px;
    border-radius: 360px;
    color: white;
}

.side-avatar-div {
    display: flex;
}

.article-box {
    display: flex;
    margin-left: 35%;
    margin-top: 20%;
}

.side-share-container {
    display: flex;
    margin-left: 20%;
    margin-top: 25vh;
}

.side-bar-build-number {
    margin-bottom: 0;
    font-size: 7px;
}


.sidenav {
    height: 100%;
    width: 80px;
    position: fixed;
    border: 0px;
    -webkit-border-radius: 0px 40px 40px 0px;
    -moz-border-radius: 0px 40px 40px 0px;
    border-radius: 0px 40px 40px 0px;
    background-color: #FFFFFF;
}


@media screen and (max-width: 450px) {
    .sidenav {
        width: 0px;
        border: 0px;
    }

    .side-avatar-icon {
        background-color: transparent;
        width: 0px;
        height: 0px;
    }

    .side-icon-facebook {
        width: 0px;
        height: 0px;
    }

    .side-icon-insta {
        width: 0px;
        height: 0px;
    }

    .side-icon-youtube {
        width: 0px;
        height: 0px;
    }

    .side-icon-whatsapp {
        width: 0px;
        height: 0px;
    }

    .div-pager-points {
        width: 0px;
        height: 0px;
    }

    .pager-dot {
        width: 0px;
        height: 0px;
        border: 0px;
        margin-top: 0px;
        background-color: transparent;
        padding: 0px;
        border-radius: 0px;
    }

    .column-style {
        padding-top: 3.5rem;
        padding-bottom: 5rem;
        padding-left: 0px;
        width: 99%;
        vertical-align: middle;
    }

}

.side-bar-class {
    margin: 0;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: left;
    width: 100%;
}

.pager-dot {
    height: 10px;
    width: 10px;
    border: 2px #1455A2;
    margin-top: 8px;
    background-color: #bbb;
    padding: 1px;
    border-radius: 5px;
}

.pager-container {
    display: flex;
    margin-top: 30%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
}

.div-pager-points {
    width: 5px;
    height: 5px;
    border: 2px #1455A2;
    border-radius: 3px;
}

.div-pager-points:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.2);
    transform: scale(1.2, 1.3);
    background-color: #1455A2;
}


.app-bar-buttons {
    background-color: #1455A2;
    border: none;
    margin-right: 3vw;
    width: 8rem;
    border-radius: 180px;
    color: white;
    font-style: initial;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

.app-bar-buttons:hover {
    background-color: RoyalBlue;
    border: none;
}

.app-bar-text-btn {
    font-weight: bolder;
    text-align: center;
    width: auto;
    margin-left: 2%;
    margin-right: 2%;
    color: #FFFFFF;
    background-color: transparent;
    border: none;
    font-size: 14px;
}

.app-bar-text-btn:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.2);
    transform: scale(1.2, 1.3);
}

.appbar-text-btn-container {
    width: 80%;
    padding-top: 0.2%;
    align-items: flex-start;
    margin-right: 5rem;
    flex-direction: row;
}


.home-section-img-logo {
    margin-top: 20vh;
    margin-left: 15vw;
    width: 25vw;
    height: 65vh;
}

.home-section-img-brain {
    margin-left: 15vw;
    width: 30vw;
    height: 80vh;
}

.head-text-background {
    width: 90%;
}

.head-text {
    font-family: "Montserrat Alternates", "Open Sans", Helvetica, Arial, sans-serif;
    position: absolute;
    top: 45%;
    left: 34%;
    font-weight: 900;
    font-size: 2vw;
    align-items: center;
    transform: translate(-40%, -50%);
}

.img-div-for-head-text {
    padding: 8px 0px;
    position: relative;
    text-align: center;
    color: white;
}

.img-main-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15vw;
    padding-top: 30vh;
}

.img-icon-home {
    width: 25rem;
    padding: 1rem;
}

.img-title-home {
    width: 13rem;
    height: 10rem;
}

@media (max-width: 700px) {
    .img-main-logo {
        display: flex;
        flex-direction: column;
        padding-left: 5vw;
        padding-top: 10vh;
    }

    .img-icon-home {
        height: 13rem;
        padding: 1rem;
    }

    .img-title-home {
        height: 10rem;
    }
}